import { Link } from 'gatsby';
import React from 'react';

import Seo from '@/components/seo';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './404.module.scss';

const NotFoundPage = () => (
  <>
    <Seo title="404: Página no encontrada" />
    <section>
      <div className={style.main}>
        <h1>404: Página no encontrada</h1>
        <p className={style.lead}>
          No pudimos encontrar lo que estás buscando.
          <br />
          Conocé nuestros <Link to={`/${ROUTE_NAMES.PRODUCTS}`}>productos</Link>,{' '}
          <Link to={`/${ROUTE_NAMES.BRANDS}`}>marcas</Link> o{' '}
          <Link to={`/${ROUTE_NAMES.CONTACT}`}>contactanos</Link>.
        </p>
      </div>
    </section>
  </>
);

export default NotFoundPage;
